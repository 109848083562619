import React, {useState, useEffect} from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import EntrataFeedLogDetails from './View/components/EntrataFeedLogDetails';
import {isEmpty} from '@/shared/helpers'

const ViewEntrataFeedLog = ( {} ) => {

    const fetchEntrataFeedLog = async ( id ) => {
        // console.log(entrataFeedLog);
        if( entrataFeedLog ) return entrataFeedLog
        try {
            const response = await axios.get(`/entrata-feed-log/${id}`);
            return response.data;
        } catch (e) {
            throw new Error(`API error:${e?.message}`);
        }
    };

    let { id } = useParams();
    const [isLoading, setIsLoading] = useState(true) //first page load!
    let [entrataFeedLog, setEntrataFeedLog] = useState(null)

    useEffect( ()=>{
        fetchEntrataFeedLog( id )
        .then( response => {
            setEntrataFeedLog(response)
            setIsLoading(false)
        })
    }, [id])

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if( !entrataFeedLog )   {
        return <p>Error loading Entrata Feed Log!</p>;
    }

    if( entrataFeedLog )   {
        return (
            <Container className="dashboard">
                <Row>
                    <Col md={12}>
                        <h3 className="page-title">Entrata Feed Log</h3>
                        <h3 class="page-subhead subhead"><Link className="" to="/">Home</Link> /
                            <Link className="" to="/entrata-feed-logs">Entrata Feed Log</Link> /&nbsp;
                            {id}
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        {/*<h3 className="page-title">{entrataFeedLog}</h3>*/}
                        {/*<h3 className="page-subhead subhead"><Link className="" to="/">Home</Link> / <Link className="" to="/roles">Roles</Link> / {role.name}</h3>*/}
                    </Col>
                </Row>
                {<EntrataFeedLogDetails entrataFeedLog={entrataFeedLog}/>}
            </Container>
        )
    }
}

export default ViewEntrataFeedLog;


