import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Card, CardBody, Col, Container, Row } from 'reactstrap';
import EntrataFeedLogIndexDataTable from './components/EntrataFeedLogIndexDataTable';

const EntrataFeedLogIndex = ({}) => {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Entrata Feed Log</h3>
          <h3 class="page-subhead subhead"><Link className="" to="/">Home</Link> / Entrata Feed Log</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
            <Card>
                <CardBody>
                  <EntrataFeedLogIndexDataTable  />
                </CardBody>
            </Card>
        </Col>
      </Row>
    </Container>
)}

export default EntrataFeedLogIndex;
